export const RECORD_LIST_ITEMS_PER_PAGE = 12

export interface IRecord {
  [field: string]: unknown
}

export interface IRecordSort<T> {
  field?: keyof T
  direction?: 'asc' | 'desc'
}

export interface IRecordTableHeading<T> {
  field: keyof T | string
  label?: string
  align?: 'left' | 'center' | 'right'
  sortable?: boolean
}

export interface IRecordTableSkeleton {
  columns: number
  rows: number
}

export interface IRecordResponse<T, M extends IRecord | undefined = undefined> {
  item: T
  meta?: M
}

export interface IPagination {
  page: number
  perPage: number
  total: number
  pagesTotal: number
  pageNext?: number
  pagePrevious?: number
  hasPageNext?: boolean
  hasPagePrevious?: boolean
}

export const PAGINATION_DEFAULT: IPagination = {
  page: 1,
  perPage: RECORD_LIST_ITEMS_PER_PAGE,
  total: 0,
  pagesTotal: 0,
  pageNext: 0,
  pagePrevious: 0,
  hasPageNext: false,
  hasPagePrevious: false,
}

export interface IRecordResponseListMeta extends IRecord {
  pagination: IPagination
}

export interface IRecordResponseListBase<
  T,
  M extends IRecordResponseListMeta = IRecordResponseListMeta,
> {
  items: T[]
  meta?: M
}

export interface IRecordResponseList<
  T,
  M extends IRecordResponseListMeta = IRecordResponseListMeta,
> extends IRecordResponseListBase<T, M> {
  meta: M
}

export interface IRecordTarget<T> {
  item?: T
  action?: string
  pending?: boolean
}

export type PartialDeep<T> = T extends object
  ? {
      [P in keyof T]?: PartialDeep<T[P]>
    }
  : T

export interface IRecordListOptions {
  refreshOnLoad: boolean
  paginateByScroll: boolean
  scrollOffset: number
  limit: number
}
